import * as React from "react"
import "../css/fish.scss"

const FishPage = () => {
  return (
    <div id="fish-container">
      <h1>Fish</h1>
      <p>asdkfasjdflkasjdfl</p>
    </div>
  )
}

export default FishPage